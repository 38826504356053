import React, { useEffect, useState } from 'react';
import { HookContext, TContextHook } from '@/context/hook';
import { TaskViewModel } from "@/modelviews/task";
import { container } from "@/di";
import moment from "moment";
import { socket } from '@/pages';
import { taskSliceAction } from '@/store/task';
import { statisticsType } from '@/models/Task';
import { getCookie } from 'cookies-next';

export const PauseTime = (props: any) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const stateTask = hooks.useSelector((state: any) => state.task);
  const taskVM = container.resolve<TaskViewModel>(TaskViewModel);
  taskVM.setHooks(hooks);
  
  const handleStartTime = async () => {
    let type = stateTask.statistics.type == statisticsType.date ? statisticsType.month : statisticsType.date;
    if(!stateTask.statistics.data) {
      type = statisticsType.date
    }
    
    if(!getCookie("access_token") || getCookie("access_token") == null) {
      return;
    }
    
    const res = await taskVM.getStatistic({
      from: type == statisticsType.month ? moment().startOf("month") : stateTask.statistics.date,
      to: type == statisticsType.month ? moment().endOf("month") : stateTask.statistics.date
    });
    hooks.dispatch(
      taskSliceAction.updateStatistics([
        {
          key: "data",
          val: {
            ...res.data,
            pause_time: res.data.pause_time && {
              ...res.data.pause_time,
              start_time_local: moment()
            }
          }
        }
      ])
    )
    
    const resTask = await taskVM.getTask({page: stateTask.task.currentPage, limit: stateTask.task.limit, hide_input_process: 1, without_old_completed: 1});
    
    hooks.dispatch(
      taskSliceAction.updateTask([
        {
          key: "data",
          val: resTask.data?.data.map((item: any) => {
            return {
              ...item,
              clientTime: moment()
            }
          })
        },
        {
          key: "lastPage",
          val: resTask.data?.last_page
        },
        {
          key: "currentPage",
          val: stateTask.task.currentPage
        }
      ])
    )
  }
  
  const handlePauseTime = async () => {
    let type = stateTask.statistics.type == statisticsType.date ? statisticsType.month : statisticsType.date;
    if(!stateTask.statistics.data) {
      type = statisticsType.date
    }
    
    if(!getCookie("access_token") || getCookie("access_token") == null) {
      return;
    }
    
    const res = await taskVM.getStatistic({
      from: type == statisticsType.month ? moment().startOf("month") : stateTask.statistics.date,
      to: type == statisticsType.month ? moment().endOf("month") : stateTask.statistics.date
    });
    hooks.dispatch(
      taskSliceAction.updateStatistics([
        {
          key: "data",
          val: {
            ...res.data,
            pause_time: res.data.pause_time && {
              ...res.data.pause_time,
              start_time_local: moment()
            }
          }
        }
      ])
    )
    
    const resTask = await taskVM.getTask({page: stateTask.task.currentPage, limit: stateTask.task.limit, hide_input_process: 1, without_old_completed: 1});
    
    hooks.dispatch(
      taskSliceAction.updateTask([
        {
          key: "data",
          val: resTask.data?.data.map((item: any) => {
            return {
              ...item,
              clientTime: moment()
            }
          })
        },
        {
          key: "lastPage",
          val: resTask.data?.last_page
        },
        {
          key: "currentPage",
          val: stateTask.task.currentPage
        }
      ])
    )
  }
  
  const [time, setTime] = useState(["", "", ""]);
  const getTotalTime = () => {
  
    const pauseTimeServer = moment(stateTask.statistics?.data?.pause_time?.start_time);
    const startPauseServer = moment(stateTask.statistics?.data?.pause_time?.now);
    const durationServer = moment.duration(startPauseServer.diff(pauseTimeServer));
    const totalTimeServer = Math.abs(durationServer.asSeconds());
    
    const startTimeLocal = moment(stateTask.statistics?.data?.pause_time?.start_time_local);
    const durationLocal = moment.duration(moment().diff(startTimeLocal));
    const totalTimeLocal = Math.abs(durationLocal.asSeconds());
    
    const totalTime = totalTimeServer + totalTimeLocal;
    
    const hours   = Math.floor(totalTime / 3600);
    const minutes = Math.floor((totalTime - (hours * 3600)) / 60);
    const seconds = Math.floor(totalTime - (hours * 3600) - (minutes * 60));
    return [`${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`];
  }
  
  useEffect(() => {
    socket.emit("authenticate", getCookie("access_token"), (data: any) => {
    });
  }, []);
  
  useEffect(() => {
    const loop = setInterval(()=> {
      if(stateTask.statistics?.data?.pause_time) {
        setTime([moment().format("YYYY年MM月DD日"), moment().format("HH:mm"), moment().format("ss")]); 
      }
    }, 1000);
    
    socket.off("change_status");
    socket.on("change_status", async (data) => {
      if(data.action == "pause") {
        await handlePauseTime();
      }
      else {
        await handleStartTime();
      }
    })
    
    return () => {
      socket.off("change_status");
      clearInterval(loop);
    }
  });
  
  const startTime = async () => {
    await taskVM.startTime();
    await handleStartTime();
  }
  
  return (
    <div>
      {
        stateTask.statistics?.data?.pause_time ? 
        <div className="endOfBreak">
          <div className="dflex">
            <p className="tit">{ stateTask.statistics?.data?.pause_time?.type == "rest" ? '休憩中' : '外出中' }</p>
            <p className="time">{ getTotalTime() }</p>
            <button className="btn btn-success" onClick={() => { startTime() } }>
              { stateTask.statistics?.data?.pause_time?.type == "rest" ? '休憩終了' : '帰社'}
            </button>
          </div>
        </div>
        : 
        <div></div>
      }
    </div>
  );
};
