import { HookContext, TContextHook } from "@/context/hook";
import { container } from "@/di";
import { IRegisterSubmitField } from "@/models/Register";
import { RegisterViewModel } from "@/modelviews/register";
import { LaboViewModel } from "@/modelviews/labo";
import React from "react";
import { forEach } from "lodash";
import {
  setFromFiledCustomSubmit,
  setFromFiledCustom,
  setFormFieldLaboSubmit,
} from "@/store/register";
import { ImplantViewModel } from "@/modelviews/setting/implant";
import { MaterialViewModel } from "@/modelviews/setting/material";
import { setLoading, setModal } from "@/store/global";
import { setLoadData } from "@/store/setting";
import { PartnerViewModel } from "@/modelviews/setting/partner";
import { orderSliceAction } from "@/store/order";
import { setVariationGlobal } from "@/store/global";

export const useFilter = (action: string = "created") => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const registerViewModel =
    container.resolve<RegisterViewModel>(RegisterViewModel);
  registerViewModel.setHooks(hooks);

  const implantViewModel =
    container.resolve<ImplantViewModel>(ImplantViewModel);
  implantViewModel.setHooks(hooks);

  const partnerViewModel =
    container.resolve<PartnerViewModel>(PartnerViewModel);
  partnerViewModel.setHooks(hooks);

  let { items, current_page, last_page } = hooks.useSelector(
    (state: any) => state.setting.partner
  );

  const {
    modals: { showScan },
  } = hooks.useSelector((state: any) => state.order);

  const modalToggle = (event: any) =>
    hooks.dispatch(
      orderSliceAction.showModal({ key: "showScan", val: !showScan })
    );

  let fiels: { [key: string]: any } = {
                                          ios_flg: { val: false },
                                          keyword: { val: "" },
                                          limit: { val: 50 },
                                          page: { val: 1 },
                                          sort_by: { val:  '' },
                                          sort_type: { val: '' },
                                          evm_publish: { val: '' },
                                        };

  let formFieldSubmit = hooks.useSelector( (state: any) => state.register.fromFiledCustom );

  const {
    manufacture,
    material,
    manufactures,
  }: { manufacture: any; material: { [key: string]: any }; manufactures: any } =
    hooks.useSelector((stage: any) => stage.setting);

  const [ intSort, setIntSort ] = React.useState( false )
  //const intSort: boolean = formFieldSubmit?.sort_by && formFieldSubmit?.sort_type 

  hooks.useEffect(() => {

    if( !intSort )
        partnerViewModel.fetchDefaultSort()
                        .then( (res: any) =>{ 
                                fiels = {
                                          ...fiels,
                                          sort_by: { val:  res?.data?.sort_by },
                                          sort_type: { val:  res?.data?.sort_type },
                                        }
                                hooks.dispatch( setFromFiledCustom(fiels) );
                                setIntSort( true )
                              })
        
      
    else
      fetchPartner({ ...hooks.getState().register.fromFiledCustom })

  }, [ intSort ]);

  const fetchPartner = async (param : any) => {
    hooks.dispatch(setLoading(true))
    if( ! param.ios_flg )
      delete param.ios_flg

    if( ! param.evm_publish )
      delete param.evm_publish
    
    partnerViewModel.fetchs(param)
                    .then((res: any) => {
                      hooks.dispatch(setLoading(false));
                      hooks.dispatch(setLoadData({ ...res.data, type: "partner" }))
    });

  }

  const handleLoadMore = (event: any) => {
    const {
      target: { scrollTop, clientHeight, scrollHeight },
    } = event;

    const page = (current_page + 1) as number;

    let _percentage: number = Math.round(
      ((scrollTop + clientHeight) / scrollHeight) * 100
    );

    if (
      _percentage == 100 &&
      last_page > current_page &&
      page != formFieldSubmit.page
    ) {

      hooks.dispatch(setFromFiledCustomSubmit({ value: page, name: "page" }));

      let param: any = { ... (hooks.getState().register.fromFiledCustom || {}) }

      if( ! param.ios_flg )
        delete param['ios_flg']

      if( ! param.evm_publish )
        delete param['evm_publish']

      partnerViewModel
        .fetchs(param)
        .then((res: any) => {
          hooks.dispatch(
            setLoadData({
              ...res.data,
              data: [...items, ...res.data.data],
              type: "partner",
            })
          );
        });
    }
  };

  const handleFilter = hooks.useCallback(
    hooks.debounce((param: any) => {
      fetchPartner(param)
      // partnerViewModel
      //   .fetchs({ ...param })
      //   .then((res: any) =>
      //     hooks.dispatch(setLoadData({ ...res.data, type: "partner" }))
      //   );
    }, 500),
    [items]
  );

  const setFormFields = (event: any) => {
    let { value } = event.target;
    const name = event.target.name as keyof IRegisterSubmitField;

    if (name == "ios_flg") 
      value = event.target.checked;

    if (name == "evm_publish") 
      value = event.target.checked ? value : '';

    //evm_publish

    hooks.dispatch(setFromFiledCustomSubmit({ value: 1, name: "page" }));
    hooks.dispatch(setFromFiledCustomSubmit({ value: value, name }));
    handleFilter({ ...hooks.getState().register.fromFiledCustom });
  };

  return { formFieldSubmit, setFormFields, items, handleLoadMore, modalToggle, setLoadData };
};
