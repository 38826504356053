import { imgAssets } from "@/constants/img";
import Image from "next/image";
import React from "react";

interface Props {
  isShow: boolean;
  onClose: () => void;
  title: string;
  textBtnClose: string;
  content: string;
  reloadPage: boolean;
}

export const ModalSuccess: React.FC<Props> = ({
  isShow,
  onClose,
  title,
  content,
  textBtnClose,
  reloadPage,
}) => {
  React.useEffect(() => {
    if (reloadPage) {
      window.location.reload();
    }
  }, [reloadPage]);

  if (!isShow) {
    return null;
  }

  return (
    <div className="modal-success">
      <div className="modal-open">
        <div className="modal fade show modalAlert" id="confirmModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="ico text_center">
                  <Image src={imgAssets.iconCheck3} alt="" />
                </div>
                <h4 className="text_center title f20">{title}</h4>
                <p className="mb10">{content}</p>
              </div>
              <div className="modal-footer dflex dflex_center">
                <button
                  className="btn btn-outline-secondary minw140"
                  type="button"
                  onClick={() => onClose()}
                >
                  {textBtnClose}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
