import Enums from "@/constants/enum";
import { validateCode } from "./form";
import _ from "lodash";

export const validateFile = (file: any, regex: any = null) => {
  if (!file) {
    return false;
  }

  const fileName = file.name;
  if (regex && regex.test(fileName)) {
    return true;
  }

  if (!/\.(jpe?g|png|jpeg)$/i.test(fileName)) {
    return "画像種類のみ";
  }
  return false;
};

export const range = (start: number, end: number) =>
  Array.from({ length: end }, (v, k) => k + start);

export const getClassInstructionStatus = (type: string) => {
  switch (type) {
    case "remake":
      return "form-check-danger";
    case "try_on":
    case "after_trial":
      return "form-check-warning";
    case "trial_complete":
      return "form-check-success";
    default:
      return "";
  }
};

export const getTextInstructionStatus = (type: string) => {
  switch (type) {
    case "remake":
      return "再製作";
    case "try_on":
      return "試適";
    case "after_trial":
      return "試適請求済";
    case "trial_complete":
      return "試適後完成";
    default:
      return "";
  }
};

export const getContextLabOrderStatus = (status: string, key: string) => {
  const labOrderStatus = Enums.LAB_ORDER_STATUS;
  let find: any = labOrderStatus.find((item: any) => item.type === status);
  if (find && find[key]) {
    return find[key];
  }
  return;
};

export const convertTzTo = (date: any, showTime = false, format = "/") => {
  if (date) {
    var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();
    var hour = d.getHours().toString();
    var min = d.getMinutes().toString();
    var milis = "0";
    // if (month.length < 2) var month = "0" + month;
    // if (day.length < 2) day = "0" + day;

    if (hour.length < 2) var hour = "0" + hour;
    if (min.length < 2) min = "0" + min;

    return !showTime
      ? [year, month, day].join(format)
      : [year, month, day].join(format) + " " + [hour, min, milis].join(":");
  }
  return "";
};

export const randomString = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const countDecimal = (value: any) => {
  if (Math.floor(value) === value) return 0;

  var str = value.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
};

export const mergeSwitchProductOrderProductDetails = (
  switchProducts: Array<any>
) => {
  let response = mapOrderProductDetail([]);
  switchProducts.forEach((item: any) => {
    item.orderProductDetails.forEach(
      (orderProductDetail: any, index: number) => {
        if (orderProductDetail && orderProductDetail.switch_type !== 0) {
          response[index] = { ...orderProductDetail };
        }
      }
    );
  });
  return response;
};

export const splitString = (val: string, length: number) => {
  if (val.length > length) {
    return val.substring(0, length).concat("...");
  }
  return val;
};

export const dataURLtoBlob = (dataurl: any) => {
  if (!dataurl) {
    return;
  }

  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const checkProductImplant = (product: any) => {
  let find = product.materials.find(
    (material: any) => material.category && material.category.type === "implant"
  );
  return find;
};

export const mapMaterialCategories = (materials: Array<any>) => {
  let materialCategories = materials
    .map((item: any) => {
      return item.category;
    })
    .filter((item: any) => {
      return item !== null;
    });

  materialCategories = _.uniqBy(materialCategories, "id");
  return materialCategories;
};

export const getMaterialsByCategory = (
  materials: Array<any>,
  categoryType: string,
  isFirstTime: boolean = false
) => {
  switch (categoryType) {
    case "implant":
      return materials.filter(
        (material: any) =>
          material.category && material.category.type === "implant"
      );
    case "metal":
      return materials.filter(
        (material: any) =>
          material.category &&
          material.category.type === "product" &&
          material.category.is_metal === true
      );
    case "other":
      // case have any material quantity >= 1
      if (isFirstTime) {
        return materials.filter(
          (material: any) =>
            material.category &&
            material.category.type === "product" &&
            material.category.is_metal === false &&
            material.quantity >= 1
        );
      }
      return materials.filter(
        (material: any) =>
          material.category &&
          material.category.type === "product" &&
          material.category.is_metal === false
      );
    default:
      return [];
  }
};

// materialCategoryType: metal, implant,  other
export const handleAddInitMaterials = (
  materials: Array<any>,
  materialCategoryType: string
) => {
  let response: any = [];
  let isFirstTime = true;
  let materialsByType: Array<any> = getMaterialsByCategory(
    materials,
    materialCategoryType,
    isFirstTime
  );
  if (materialsByType.length > 0) {
    if (materialCategoryType === "other") {
      for (let i = 0; i < materialsByType.length; i++) {
        let material: any = materialsByType[i];
        response.push({
          id: material?.id || null,
          code: material?.code || null,
          name: material?.name || null,
          category: material.category,
          variation: null,
          manufacture: material?.manufacture,
          quantity: material?.quantity || 1,
          is_print_barcode: false,
          is_stock: false,
          variation_id: material?.variation ? material.variation.id : null,
        });
      }
      return response;
    }

    let first = materialsByType[0];
    response.push({
      id: first?.id || null,
      code: first?.code || null,
      name: first?.name || null,
      category: first.category,
      variation:
        first.category.is_metal && first.category.is_metal === true
          ? null
          : first?.variation,
      manufacture: first?.manufacture,
      quantity: first?.quantity || 1,
      is_stock: false,
      is_print_barcode: false,
      variation_id: first?.variation ? first.variation.id : null,
    });
  }
  return response;
};

export const getAliasMilkTooth = (index: number) => {
  switch (index) {
    case 0:
      return "A";
    case 1:
      return "B";
    case 2:
      return "C";
    case 3:
      return "D";
    case 4:
      return "E";
  }
};

export const showVisibleMilkTooth = (toothIndex: number) => {
  let visible = null;
  if (toothIndex > 24) {
    visible = 4 - Math.abs(toothIndex - 29);
  } else if (toothIndex > 16) {
    visible = Math.abs(24 - toothIndex);
  } else if (toothIndex > 8) {
    visible = 4 - Math.abs(toothIndex - 13);
  } else {
    visible = Math.abs(toothIndex - 8);
  }
  return getAliasMilkTooth(visible);
};

export const showVisibleToothProduct = (product: any) => {
  let response = {
    topRight: "左上",
    topLeft: "右上",
    bottomRight: "左下",
    bottomLeft: "右下",
  };
  let orderDetails = product.orderProductDetails;

  orderDetails.forEach((item: any, index: number) => {
    if (item.switch_type !== 0) {
      if (index < 8) {
        if (item.milk_teeth_flg) {
          response.topLeft += showVisibleMilkTooth(item.tooth_index_id);
        } else {
          response.topLeft += `${8 - index}`;
        }
      } else if (index >= 8 && index < 16) {
        if (item.milk_teeth_flg) {
          response.topRight += showVisibleMilkTooth(item.tooth_index_id);
        } else {
          response.topRight += `${item.tooth_index_id - 8}`;
        }
      } else if (index >= 16 && index < 24) {
        if (item.milk_teeth_flg) {
          response.bottomLeft += showVisibleMilkTooth(item.tooth_index_id);
        } else {
          response.bottomLeft += `${24 - index}`;
        }
      } else if (index >= 24 && index < 32) {
        if (item.milk_teeth_flg) {
          response.bottomRight += showVisibleMilkTooth(item.tooth_index_id);
        } else {
          response.bottomRight += `${item.tooth_index_id - 24}`;
        }
      }
    }
  });
  let str = response.topLeft.length > 2 ? response.topLeft + "\n" : "";
  str += response.topRight.length > 2 ? response.topRight + "\n" : "";
  str += response.bottomLeft.length > 2 ? response.bottomLeft + "\n" : "";
  str += response.bottomRight.length > 2 ? response.bottomRight : "";
  return str;
};

export const showClassAndToothProduct = (product: any) => {
  let response = {
    topRight: {
      class: "lineBottom lineLeft",
      data: "",
    },
    topLeft: {
      class: "lineBottom lineRight",
      data: "",
    },
    bottomRight: {
      class: "lineTop lineLeft",
      data: "",
    },
    bottomLeft: {
      class: "lineTop lineRight",
      data: "",
    },
  };
  let orderDetails = product.orderProductDetails;

  orderDetails.forEach((item: any, index: number) => {
    if (item.switch_type !== 0) {
      if (index < 8) {
        if (item.milk_teeth_flg) {
          response.topLeft.data += showVisibleMilkTooth(item.tooth_index_id);
        } else {
          response.topLeft.data += `${8 - index}`;
        }
      } else if (index >= 8 && index < 16) {
        if (item.milk_teeth_flg) {
          response.topRight.data += showVisibleMilkTooth(item.tooth_index_id);
        } else {
          response.topRight.data += `${item.tooth_index_id - 8}`;
        }
      } else if (index >= 16 && index < 24) {
        if (item.milk_teeth_flg) {
          response.bottomLeft.data += showVisibleMilkTooth(item.tooth_index_id);
        } else {
          response.bottomLeft.data += `${24 - index}`;
        }
      } else if (index >= 24 && index < 32) {
        if (item.milk_teeth_flg) {
          response.bottomRight.data += showVisibleMilkTooth(
            item.tooth_index_id
          );
        } else {
          response.bottomRight.data += `${item.tooth_index_id - 24}`;
        }
      }
    }
  });
  return response;
};

export const mapOrderProductDetail = (orderProductDetail: any) => {
  let data: Array<any> = [];
  range(1, 32).forEach((toothIndex: any) => {
    let find = orderProductDetail.find(
      (item: any) =>
        (item && item.ToothIndex?.index === toothIndex) ||
        (item && item.tooth_index_id === toothIndex)
    );
    if (find) {
      data.push({
        switch_type: find.switch_type,
        tooth_index_id: toothIndex,
        milk_teeth_flg: find.milk_teeth_flg || false,
      });
    } else {
      data.push({
        switch_type: 0,
        tooth_index_id: toothIndex,
        milk_teeth_flg: false,
      });
    }
  });
  return data;
};

export const mapSaveOrder = (order: any) => {
  return {
    old_data: order?.old_data,
    id: typeof order.id === "number" ? order.id : undefined,
    // patient_name: order.patientName,
    patient_name_sei: order.patientNameSei,
    patient_name_mei: order.patientNameMei,
    appointment_date: convertTzTo(order.appointmentDate, false, "-"),
    appointment_time: order.appointmentTime ? order.appointmentTime : null,
    clinic_id: order.clinic.id,
    has_change: order.hasChange,
    instruction_status:
      order.instructionStatus !== "" ? order.instructionStatus : [],
    OrderProduct: order.orderProducts.map((product: any) => ({
      bridge_detail_material_flg: product.bridgeDetailMaterialFlg,
      parent_order_id: product.parentOrderId,
      instruction_status: product.instructionStatus,
      product_id: product.id,
      remake: product.remake,
      quantity: product.quantity,
      is_new: product.isNew,
      variation_id: product.variation_id,
      ProductSwitch: product.selectedSwitchProducts.map(
        (switchProduct: any) => ({
          product_id: switchProduct.DesProduct.id,
          remake: switchProduct.remake || false,
          quantity: switchProduct.quantity,
          OrderProductDetail: switchProduct.orderProductDetails.filter(
            (item: any) => item.switch_type !== 0
          ),
          materials: switchProduct.materialSelecteds.map((material: any) => ({
            material_id: material.id,
            quantity: material.quantity,
            is_stock: material.is_stock,
            variation_id: material.variation_id,
            is_print_barcode: material.is_print_barcode,
          })),
        })
      ),
      materials: product.materialSelecteds.map((material: any) => ({
        material_id: material.id,
        quantity: material.quantity,
        is_stock: material.is_stock,
        variation_id: material.variation_id,
        is_print_barcode: material.is_print_barcode,
      })),
      OrderProductDetail: product.orderProductDetails.filter(
        (item: any) => item.switch_type !== 0
      ),
      OrderLink: product.orderLinks,
    })),
    OrderParent: order.orderParents.map((orderParent: any) => ({
      order_id: orderParent.parent_id,
      instruction_status: orderParent.instruction_status,
    })),
    start_counting_time: order?.server_time,
  };
};

export const mapSaveOrderImages = (order: any) => {
  let depositeImages = order.EVMDepositImage.map((item: any, index: any) => ({
    id: item.id !== undefined ? item.id : "",
    image: item.image_url,
    type: item.type,
    image_index: index + 1,
  }));
  let finishImages = order.EVMFinishImage.map((item: any, index: any) => ({
    id: item.id !== undefined ? item.id : "",
    image: item.image_url,
    type: item.type,
    image_index: index + 1,
  }));
  return [...depositeImages, finishImages].flat();
};

export const getMaterialsByOrangeLabel = (
  order: any,
  isInheritProductFunc: Function
) => {
  let orderMapping = mapOrder(order);
  let data: any = [];
  let inheritProducts: any = [];
  let noInheritProducts: any = [];
  let materialProductCodes: any = [];
  let materialProductWithoutCodes: any = [];
  orderMapping.orderProducts.forEach((orderProduct: any) => {
    let isShowCodeProduct = isInheritProductFunc(
      orderMapping.instructionStatus,
      orderProduct
    );
    if (isShowCodeProduct) {
      inheritProducts.push(orderProduct);
    } else {
      noInheritProducts.push(orderProduct);
    }
  });

  inheritProducts.forEach((orderProduct: any) => {
    let materialSelecteds = orderProduct.materialSelecteds;
    if (orderProduct.bridgeDetailMaterialFlg) {
      materialSelecteds = orderProduct.selectedSwitchProducts
        .map(
          (selectedSwitchProduct: any) =>
            selectedSwitchProduct.materialSelecteds
        )
        .flat();
    }

    materialSelecteds.forEach((material: any) => {
      let findIndex = materialProductCodes.findIndex(
        (item: any) =>
          item.id === material.id &&
          item.variation?.id === material.variation?.id
      );
      if (findIndex !== -1) {
        materialProductCodes[findIndex] = {
          ...materialProductCodes[findIndex],
          quantity:
            materialProductCodes[findIndex].quantity + material.quantity,
        };
      } else {
        materialProductCodes.push({
          id: material.id,
          is_print_barcode: material.is_print_barcode,
          is_stock: material.is_stock,
          category: material.category,
          metal_type_id: material?.metal_type_id,
          code: material.code,
          name: material.name,
          quantity: material.quantity ? parseInt(material.quantity) : 0,
          manufacture: material.manufacture,
          variation: material.variation,
          isInherit: true,
        });
      }
    });
  });

  noInheritProducts.forEach((orderProduct: any) => {
    let materialSelecteds = orderProduct.materialSelecteds;
    if (orderProduct.bridgeDetailMaterialFlg) {
      materialSelecteds = orderProduct.selectedSwitchProducts
        .map(
          (selectedSwitchProduct: any) =>
            selectedSwitchProduct.materialSelecteds
        )
        .flat();
    }

    materialSelecteds.forEach((material: any) => {
      let findIndex = materialProductWithoutCodes.findIndex(
        (item: any) =>
          item.id === material.id &&
          item.variation?.id === material.variation?.id
      );
      if (findIndex !== -1) {
        materialProductWithoutCodes[findIndex] = {
          ...materialProductWithoutCodes[findIndex],
          quantity:
            materialProductWithoutCodes[findIndex].quantity + material.quantity,
        };
      } else {
        materialProductWithoutCodes.push({
          id: material.id,
          is_print_barcode: material.is_print_barcode,
          is_stock: material.is_stock,
          category: material.category,
          metal_type_id: material?.metal_type_id,
          code: material.code,
          name: material.name,
          quantity: material.quantity ? parseInt(material.quantity) : 0,
          manufacture: material.manufacture,
          variation: material.variation,
          isInherit: false,
        });
      }
    });
  });
  data = [...materialProductWithoutCodes, ...materialProductCodes];
  return data;
};

const groupProduct = (orderProduct: any, productMapping: Array<any>, isInherit: boolean) => {
  let index = productMapping.findIndex(
    (item: any) =>
      item.id === orderProduct.id &&
      item.variation_id === orderProduct?.variation_id
  );
  if (index !== -1) {
    productMapping[index] = {
      ...productMapping[index],
      quantity: productMapping[index].quantity + orderProduct.quantity,
    };
  }else {
    productMapping.push({ ...orderProduct, isInherit: isInherit });
  }
  return productMapping;
}

const getBridgeProductsMapping = (orderProduct: any, orderProductMapping: Array<any>, isInherit: Boolean) => {
  let orderBridgeProducts = orderProduct.selectedSwitchProducts.map((item: any) => ({
    id: item.DesProduct?.id,
    quantity: item.quantity,
    name: item.DesProduct?.name,
    count_flg: item.count_flg,
    variation_id: orderProduct.variation_id,
    variation_name: orderProduct.variation_name,
    variation_code: orderProduct.variation_code,
    isInherit: isInherit
  }));
  orderProductMapping = [...orderProductMapping, ...orderBridgeProducts];

  orderProductMapping = orderProductMapping.reduce((acc: Array<any>, item: any) => {
    const itemIndex = acc.findIndex((product: any) => product.id === item.id);
    if (itemIndex !== -1) {
      acc[itemIndex].quantity += item.quantity
    } else {
     acc.push(item)
    }
    return acc;
  }, [])
  return orderProductMapping;
}


export const getProductsByOrangeLabel = (order: any, isInheritProductFunc: Function) => {
  let orderMapping = mapOrder(order);
  let data: any = [];
  let inheritProducts: any = [];
  let noInheritProducts: any = [];
  orderMapping.orderProducts.forEach((orderProduct: any) => {

    let isInheritProduct = isInheritProductFunc(
      orderMapping.instructionStatus,
      orderProduct
    );
    if (isInheritProduct) {

      if(orderProduct.breakdownFlg)
      {
        inheritProducts = getBridgeProductsMapping(orderProduct, inheritProducts, isInheritProduct)
      }
      else {
        inheritProducts = groupProduct(orderProduct, inheritProducts, isInheritProduct);
      }
    } else {
      if(orderProduct.breakdownFlg)
      {
        noInheritProducts = getBridgeProductsMapping(orderProduct, noInheritProducts, isInheritProduct)
      }
      else {
        noInheritProducts = groupProduct(orderProduct, noInheritProducts, isInheritProduct);
      }
    }
  });

  data = [...noInheritProducts, ...inheritProducts];
  return data;
} 

export const mapOrder = (order: any) => {
  return {
    old_data: order,
    id: order.id,
    orderCode: order.order_code,
    clinic: order?.Clinic,
    clinicCode: order.Clinic?.LabPartnerOne?.id
      ? order.Clinic?.LabPartnerOne?.id.toString()
      : order?.clinicCode,
    patientName: order.Patient ? order.Patient.name : null,
    patientNameSei: order.Patient ? order.Patient.name_sei : null,
    patientNameMei: order.Patient ? order.Patient.name_mei : null,
    appointmentDate:
      order.Appointment && order.Appointment.date
        ? new Date(order.Appointment.date)
        : null,
    appointmentTime: order.Appointment ? order.Appointment.start_time : null,
    hasChange: false,
    instructionStatus:
      order.instruction_status && order.instruction_status !== ""
        ? order.instruction_status
        : [],
    EVMDepositImage: order.EVMDepositImage || [],
    EVMFinishImage: order.EVMFinishImage || [],
    EvinoteFile: order.EvinoteFile || [],
    FirstInputStaff: order.FirstInputStaff,
    LastConfirmStaff: order.LastConfirmStaff,
    LastEditStaff: order.LastEditStaff,
    orderProducts: order.hasOwnProperty("OrderProduct")
      ? order.OrderProduct.map((orderProduct: any, productIndex: number) => ({
          index: productIndex,
          id: orderProduct.Product?.id,
          name: orderProduct.Product.name,
          code: orderProduct.Product.code,
          bridgeDetailMaterialFlg: orderProduct.bridge_detail_material_flg,
          breakdownNumbers: orderProduct.Product.breakdown_numbers,
          partBreakDownNumbers: orderProduct.Product.part_breakdown_numbers,
          productClass: orderProduct.Product.ProductCategory.ProductClass,
          remake: orderProduct.remake,
          quantity: orderProduct.quantity,
          isNew: orderProduct.is_new,
          variation_id: orderProduct.ProductVariation?.id,
          variation_name: orderProduct.ProductVariation?.name,
          variation_code: orderProduct.ProductVariation?.code,
          breakdownFlg: orderProduct.Product?.breakdown_flg,
          variationFlg: orderProduct.Product?.variation_flg,
          noPartFlg: orderProduct.Product?.no_part_flg,
          variations: orderProduct.Product?.Variation || [],
          productSwitches: orderProduct.Product?.ProductSwitches,
          parentOrderId: orderProduct?.parent_order_id,
          instructionStatus: orderProduct?.instruction_status,
          printBillFlg: orderProduct.Product?.print_bill_flg,
          selectedSwitchProducts: orderProduct.OrderProductSwitch.map(
            (item: any) => ({
              id: item.id,
              quantity: item.quantity,
              remake: item.remake,
              count_flg:
                item.Product.hasOwnProperty("ProductSwitchSetting") &&
                item.Product.ProductSwitchSetting
                  ? item.Product.ProductSwitchSetting.count_flg
                  : false,
              DesProduct: orderProduct.Product.ProductSwitches.map(
                (item: any) => item.DesProduct
              )
                .flat()
                .find((product: any) => product.id === item.Product.id) ? {
                ...orderProduct.Product.ProductSwitches.map(
                  (item: any) => item.DesProduct
                )
                  .flat()
                  .find((product: any) => product.id === item.Product.id) ,
              } : null,
              orderProductDetails: mapOrderProductDetail(
                item.OrderProductDetail ? item.OrderProductDetail : []
              ),
              materials: orderProduct.Product?.ProductSwitches.find(
                (productSwitch: any) =>
                  productSwitch.des_product_id === item.Product.id
              )
                ? mapProductMaterial(
                    orderProduct.Product?.ProductSwitches.find(
                      (productSwitch: any) =>
                        productSwitch.des_product_id === item.Product.id
                    ).DesProduct
                  )
                : [],
              materialSelecteds:
                item.OrderProductSwitchMaterial.map((item: any) => ({
                  category: {
                    ...item.Material.MaterialCategory,
                  },
                  id: item.Material.id,
                  code: item.Material.code,
                  name: item.Material.name,
                  manufacture: item.Material.ImplantManufacture,
                  variation: item.Variation,
                  quantity: item.quantity,
                  is_stock: item.is_stock,
                  variation_id: item.Variation?.id,
                  is_print_barcode: item.is_print_barcode,
                })) || [],
            })
          ),
          materialSelecteds:
            orderProduct.OrderProductMaterial.map((item: any) => ({
              category: {
                ...item.Material.MaterialCategory,
              },
              id: item.Material.id,
              code: item.Material.code,
              name: item.Material.name,
              metal_type_id: item.Material?.metal_type_id,
              manufacture: item.Material.ImplantManufacture,
              variation: item.Variation,
              quantity: item.quantity,
              is_stock: item.is_stock,
              variation_id: item.Variation?.id,
              is_print_barcode: item.is_print_barcode,
            })) || [],
          materials: orderProduct.Product.breakdown_flg
            ? mapMaterialProductSwitches(
                orderProduct.Product.ProductSwitches.filter(
                  (productSwitch: any) =>
                    orderProduct.OrderProductSwitch.map(
                      (orderProductSwitch: any) => orderProductSwitch.product_id
                    ).includes(productSwitch.des_product_id)
                )
              )
            : mapProductMaterial(orderProduct.Product),
          orderProductDetails: mapOrderProductDetail(
            orderProduct.OrderProductDetail
          ),
          orderLinks: orderProduct.OrderLink.length
            ? orderProduct.OrderLink.map((orderLink: any) => {
                return _.sortBy(
                  orderLink.OrderLinkItem.map(
                    (orderLinkItem: any) => orderLinkItem.tooth_index_id
                  )
                );
              })
            : [],
        }))
      : [],
    orderParents: order.hasOwnProperty("OrderParent") ? order.OrderParent : [],
    userProcesses: order.hasOwnProperty("UserProcess") ? order.UserProcess : [],
    is_over_time: order.is_over_time,
    server_time: order?.server_time,
  };
};

export const mapProductMaterial = (product: any) => {
  return product.hasOwnProperty("Meterial")
    ? product.Meterial.map((item: any) => ({
        id: item.id,
        name: item.name,
        code: item.code,
        metal_type_id: item?.metal_type_id,
        sort_order: item.sort_order,
        category: item.MaterialCategory,
        quantity: item.ProductMaterial.quantity
          ? parseInt(item.ProductMaterial.quantity)
          : 0,
        variation: item.Variation,
      }))
    : [];
};

export const mapProduct = (product: any) => {
  return {
    id: product.id,
    productClass: product.currentClass,
    name: product.name,
    code: product.code,
    breakdownNumbers: product.breakdown_numbers,
    breakdownFlg: product.breakdown_flg,
    variationFlg: product.variation_flg,
    noPartFlg: product.no_part_flg,
    partBreakDownNumbers: product.part_breakdown_numbers,
    variations: product.Variation || [],
    productSwitches: product.ProductSwitches || [],
    selectedSwitchProducts: [],
    materialSelecteds: [],
    materials:
      product.Meterial.map((item: any) => ({
        id: item.id,
        name: item.name,
        code: item.code,
        metal_type_id: item?.metal_type_id,
        sort_order: item.sort_order,
        category: item.MaterialCategory,
        quantity: item.ProductMaterial.quantity
          ? parseInt(item.ProductMaterial.quantity)
          : 0,
        variation: item.Variation,
      })) || [],
    remake: false,
    quantity: 1,
    isNew: true,
    variation_id: null,
    variation_name: null,
    instructionStatus: product.instruction_status,
    printBillFlg: product.print_bill_flg,
    orderProductDetails: range(1, 32).map((val, index) => ({
      switch_type: 0,
      tooth_index_id: val,
      milk_teeth_flg: false,
    })),
    orderLinks: [],
    bridgeDetailMaterialFlg: false,
  };
};

// format: moment('2022-04-15'),
export const getDatesBetween = (
  startDate: any,
  endDate: any,
  format = null
) => {
  let currentDate: any = new Date(startDate);
  endDate = new Date(endDate);
  let dates: any = [];
  while (currentDate <= endDate) {
    if (format) {
      dates.push(convertTzTo(new Date(currentDate), false, format));
    }
    let getCurrentDate = new Date(currentDate);
    dates.push(getCurrentDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

// date: YYYY-MM-DD
export const getDayJP = (date: any) => {
  if (!date) {
    return "";
  }

  let arr = ["日", "月", "火", "水", "木", "金", "土"];
  let day = new Date(date).getDay();
  return arr[day];
};

export const validateSubmitOrder = (orderProgresses: any) => {
  let isValid = true;
  orderProgresses.forEach((item: any) => {
    if (item.orderProducts.length <= 0) {
      isValid = false;
      return isValid;
    }
  });

  return isValid;
};

export const mapMaterialProductSwitches = (productSwitches: Array<any>) => {
  if (productSwitches.length <= 0) {
    return productSwitches;
  }

  productSwitches = productSwitches.map((productSwitch: any) => ({
    ...productSwitch,
    DesProduct: {
      ...productSwitch.DesProduct,
      Meterial: productSwitch.DesProduct.breakdown_flg
        ? []
        : productSwitch.DesProduct.Meterial.map((item: any) => ({
            id: item.id,
            name: item.name,
            code: item.code,
            sort_order: item.sort_order,
            category: item.MaterialCategory,
            quantity:
              item.MaterialCategory.type === "product" &&
              item.MaterialCategory.is_metal === false
                ? parseInt(item.ProductMaterial.quantity) *
                  parseInt(productSwitch.quantity || 1)
                : parseInt(item.ProductMaterial.quantity),
            variation: item.Variation,
          })),
    },
  }));

  let products = productSwitches.map(
    (productSwitch: any) => productSwitch.DesProduct
  );
  let metalMaterials = products.map((product: any) =>
    product.Meterial.filter(
      (material: any) =>
        material.category &&
        material.category.is_metal === true &&
        material.category.type === "product"
    )
  );
  let intersectionMetalMaterials = metalMaterials.reduce((a, b) =>
    a.filter((c: any) => b.map((item: any) => item.id).includes(c.id))
  );

  let implantMaterials = products
    .map((product: any) =>
      product.Meterial.filter(
        (material: any) =>
          material.category && material.category.type === "implant"
      )
    )
    .flat();

  let otherMaterials = products
    .map((product: any) =>
      product.Meterial.filter(
        (material: any) =>
          material.category &&
          material.category.is_metal === false &&
          material.category.type === "product"
      )
    )
    .flat();

  // merge quantity
  let interSectionOtherMaterisl = Object.values(
    otherMaterials.reduce((a, { id, ...b }) => {
      if (!a[`id-${id}`]) {
        a[`id-${id}`] = {
          quantity: 0,
        };
      }

      a[`id-${id}`] = {
        id,
        ...a[`id-${id}`],
        ...b,
        quantity: a[`id-${id}`].quantity + b.quantity,
      };
      return a;
    }, {})
  );

  return [
    ...intersectionMetalMaterials,
    ...interSectionOtherMaterisl,
    ...implantMaterials,
  ];
};

export const validateScanOrderCode = (
  orderCode: any,
  checkInValid: boolean = false
) => {
  const arrayKey = [
    "Backspace",
    "Tab",
    "Enter",
    "Shift",
    "Control",
    "Alt",
    "Pause",
    "CapsLock",
    "Escape",
    "PageUp",
    "PageDown",
    "End",
    "Home",
    "ArrowLeft",
    "ArrowUp",
    "ArrowRight",
    "ArrowDown",
    "PrintScreen",
    "Insert",
    "Delete",
  ];
  let _contain: any = /\d/;
  let result: any = orderCode;

  arrayKey.forEach((key: string) => (result = result.replaceAll(key, "")));

  const codeRegex = new RegExp(/^[-0-9A-Z.$/+% ]+$/, "g");

  if (!codeRegex.test(result) || !_contain.test(result) || result.length <= 2)
    return "";

  return result;
};

export const getProcessGroupName = (processGroup: Array<string>) => {
  let action = processGroup.map((item: any) => item.split("/")).flat();
  action = _.uniq(action);
  if (action.length > 0) {
    if (action.length === 1) {
      return action[0];
    } else {
      let processName = action[0];
      action.shift();
      return processName + `(${action.join("/")})`;
    }
  }
  return null;
};
export const validateRegexInput = (regex: any, value: any) => {
  let getValue = value;
  if (getValue && regex.test(getValue) === false) {
    return false;
  }
  return true;
};

export const showOrangeLabel = (order: any) => {
  if (
    (order.instructionStatus.includes("try_on") ||
      order.instructionStatus.includes("trial_complete")) &&
    order.orderProducts.filter((product: any) => !product.printBillFlg).length >
      0
  ) {
    return true;
  }
  return false;
};

export const getProcessGroupOne = (name: any) => {
  name = name.split("/");
  return name?.length > 1 ? `${name[0]}(${name[1]})` : name;
};

export const getClassOfOrder = (order: any) => {
  // don't have product
  if (order.orderProducts.length <= 0) {
    return [
      {
        id: null,
        Color: {
          hex_code: "#ED5555",
        },
        name: "技工物未入力",
      },
    ];
  }
  let data = order.orderProducts.map((item: any) => item.productClass);
  return _.uniqBy(data, "id");
};

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const validateNumberInt = (number: any) =>
  Number(Number(number).toFixed(2).toString().split(".")[0]);
